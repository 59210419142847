<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-card class="card">
          <template #header>
            <b-container fluid class="p-0 d-flex justify-content-between">
              <h6 class="mb-0">Xu hướng HbA1C</h6>
              <multiselect
                class="hba1c-rounded"
                style="border-radius: 50px; width: 100px"
                v-model="selectedTime"
                :options="timeOptions"
                :searchable="false"
                :show-labels="false"
                label="text"
              ></multiselect>
            </b-container>
          </template>
          <b-overlay :show="loading">
            <div style="height: 350px; margin: auto">
              <v-chart :option="option" autoresize ref="line" />
            </div>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col> </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { use } from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  MarkLineComponent,
  LegendComponent,
  DataZoomComponent,
} from 'echarts/components';
import { BarChart, LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import VChart from 'vue-echarts';
use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  MarkLineComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
]);

export default {
  name: 'HBA1CTab',
  components: { Multiselect, VChart },
  props: {
    userId: {
      type: String,
    },
    selectDay: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      timeOptions: [
        { text: '6 tháng', value: 1 },
        { text: '1 năm', value: 2 },
        { text: '2 năm', value: 3 },
      ],
      selectedTime: { text: '1 năm', value: '1' },

      option: {
        color: [],
        legend: {
          data: [],
          bottom: 0,
        },

        dataZoom: {
          start: 0,
          type: 'inside',
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: [],
          },
          {
            type: 'category',
            boundaryGap: true,
            data: [],
            show: false,
          },
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            max: 12,
            min: 0,
            // boundaryGap: [0.2, 0.2],
          },
          {
            type: 'value',
            scale: true,
            max: 12,
            min: 0,
            show: false,
            // boundaryGap: [0.2, 0.2],
          },
        ],
        series: [
          {
            name: 'Tuyệt vời',
            type: 'bar',

            data: [],
            label: {
              show: true,
              position: 'top',
            },
          },
          {
            name: 'Tốt',
            type: 'bar',
            data: [],
            barGap: '-100%',
            label: {
              show: true,
              position: 'top',
            },
          },
          {
            name: 'Khá cao',
            type: 'bar',
            barGap: '-100%',
            data: [],
            label: {
              show: true,
              position: 'top',
            },
          },
          {
            name: 'Rất cao',
            type: 'bar',
            barGap: '-100%',
            data: [],
            label: {
              show: true,
              position: 'top',
            },
          },
          {
            type: 'line',
            data: [],
            symbol: 'circle',
            lineStyle: {
              color: '#888C9F',
              width: 2,
              shadowColor: '#888C9F',
              shadowBlur: 10,
              shadowOffsetY: 8,
            },
          },
        ],
      },

      searchParams: {
        currentDateTime: parseInt(new Date().getTime() / 1000),
        takeAll: true,
      },
      loading: false,
    };
  },
  computed: {
    patientId() {
      return this.userId;
    },
  },
  watch: {
    selectedTime: {
      handler() {
        this.loadData();
      },
    },
  },
  methods: {
    covertTimeStamp(time) {
      return this.$moment(time).format('DD/MM/YYYY');
    },
    async loadData() {
      this.loading = true;
      this.option.series.forEach((e) => (e.data = []));
      this.option.xAxis.forEach((e) => (e.data = []));
      this.option.yAxis.forEach((e) => (e.data = []));
      this.option.legend.data = [];
      this.option.color = [];
      this.$refs?.line?.clear();
      try {
        let { data } = await this.$api.get('Admin/HbA1C/Trend', {
          params: {
            ...this.searchParams,
            trendType: this.selectedTime.value,
            patientId: this.patientId,
          },
        });
        if (data.trendItems?.items.length) {
          for (const el of data.trendItems.items) {
            for (const e of this.option.series) {
              if (el.type === e.name) {
                e.data.push({
                  value: el.hbA1C,
                  itemStyle: {
                    color: el.color,
                  },
                });
              } else if (e.type == 'line') {
                e.data.push({
                  value: el.hbA1C,
                });
              } else {
                e.data.push('-');
              }
            }
            for (const data of this.option.xAxis) {
              data.data.push(this.$moment(el.date * 1000).format('MM/YYYY'));
            }
          }
        }
        data.legends.forEach((e) => {
          let [name, color] = e;
          this.option.color.push(color);
          this.option.legend.data.push(name);
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$toastr.e(error, 'ERROR');
      }
    },
  },
  created() {
    this.loadData();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.hba1c-rounded {
  border-radius: 50px;
}
</style>
